import React, { useEffect, useState, useRef } from 'react'
import { graphql } from 'gatsby'
import map from 'lodash/map'
import get from 'lodash/get'
import chunk from 'lodash/chunk'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import cx from 'classnames'
import sal from 'sal.js'
import 'sal.js/dist/sal.css'
import 'swiper/css'

import useWindowSize from '../hook/useWindowSize'
import useScrollPosition from '../hook/useScrollPosition'

import Seo from '../components/seo'
import SignUpForm from '../components/SignUpForm'
import Layout from '../components/layout'
import IGPost from '../components/igPost'

import ogImage from '../../static/og_image.png'
import heroMock from '../../static/hero-mock.png'
import heroVideo from '../../static/hero_demo.mp4'

// temp logo
import LogoY from '../../static/svg/omg.svg'
import Evaluation from '../../static/svg/evaluation.svg'
import Pipeline from '../../static/svg/pipeline.svg'
import Talents from '../../static/svg/talents.svg'
import Virtual from '../../static/svg/virtual.svg'
import Profile from '../../static/svg/profile.svg'

import AlarmIcon from '../../static/svg/alarmIcon.svg'
import EmployerBrandIcon from '../../static/svg/employerBrandIcon.svg'
import AssessmentIcon from '../../static/svg/assessmentIcon.svg'
import OmniChannelIcon from '../../static/svg/omniChannelIcon.svg'
import ManyPositionIcon from '../../static/svg/manyPositionIcon.svg'
import DiversityIcon from '../../static/svg/diversityIcon.svg'

import * as styles from './index.module.css'
import '@fontsource/quicksand/700.css'
import '@fontsource/quicksand'
import '@fontsource/quicksand/300.css'
import '@fontsource/arvo/700.css'
import '@fontsource/arvo/400.css'

const TEMP_FEATURE = [
  {
    title: 'Build Employer Profile',
    content: [
      'Employer profiles is the first touchpoint in employer branding strategy',
      'Showcase your organization in OnMyGrad Space with top talents from universities in Southeast Asia',
      'Convey your vision, mission, and value in your own wordings to attract the like-mind talents',
    ],
  },
  {
    title: 'Create Early Onboarding or Virtual Job Experiences',
    content: [
      "Extend your company's employee onboarding process to campus and become top-of-mind employers",
      'Simulate real work tasks from your organization in virtual space and distribute them to our student communities',
      "Build case competition, stock pitch, marketing proposal, financial forecast, or any job tasks looking for ideas and innovation, with the help of OnMyGrad's tools & templates",
    ],
  },
  {
    title: 'Engage with top young talents',
    content: [
      'Build your own talent pool in one of the largest communities of passionate candidates who are devoted to kickstart their careers at key moments',
      'Virtual-meet potential candidates together with their authentic output',
      'Build rapport with potential candidates without the hassle of managing multiple social media channels',
    ],
  },
  {
    title: 'Support Campus Recruitment Pipeline',
    content: [
      "Spotlight candidates who'd work best at your company, not who looked best on resume",
      'Apply targetive filters to prioiritize candidates and discover their talents with multiple perspectives',
      'Connect shortlisted candidates to your existing recruitment schemes, ie pre-internship, internship, or graduate recruitment programmes',
    ],
  },
  {
    title: 'Evaluation & Performance metrics',
    content: [
      'Develop a sustainable campus recruitment strategy with the performance metrics of your virtual job experiences',
      'Receive direct feedback of your brand, your employer brand, and even your job offer packages',
      'Build readiness to embrace future of work in your organization starting from recruitment',
      'Acquire the latest market insight to be ahead of the game in talent acquisition',
    ],
  },
]

const TEMP_PROBLEMS = [
  {
    title: 'Early Engagement',
    des: 'Move early to becomes the Top-of-Mind employer',
    icon: 'AlarmIcon',
  },
  {
    title: 'Employer Branding',
    des: 'Build common values and clear career progression with talents',
    icon: 'EmployerBrandIcon',
  },

  {
    title: 'Customized Assessment',
    des: 'Match candidate to various needs from stakeholders',
    icon: 'AssessmentIcon',
  },
  {
    title: 'Omni-channel commitment',
    des: 'Manage a wide mix of offline and online touch-points',
    icon: 'OmniChannelIcon',
  },
  {
    title: 'Multiple positions',
    des: 'Invest repeated efforts for different positions, ranging from pre-internship to graduate programmes',
    icon: 'ManyPositionIcon',
  },
  {
    title: 'Diversity',
    des: 'Embrace diversity to reach more sustainable goals',
    icon: 'DiversityIcon',
  },
]

const TEMP_TESTIMONIAL = [
  {
    heading: 'Finally!',
    content: `Finally there's a virtual space that allows us to demonstrate what we are capable of.`,
    name: 'An OnMyGrad User',
    avatar: '',
  },
  {
    heading: 'innovative virtual recruitment engagement',
    content:
      'OnMyGrad provides an effective platform for P&G to share innovative virtual recruitment engagement with university students in pandemic period (i.e. Virtual Industry Learning Series).Besides, the Q&A platform helps employers to understand students’ feedback and engage with them.',
    name: 'Ms Linda Wong',
    title: 'Campus Recruitment Lead, P&G',
    avatar: '',
  },
  {
    heading: 'Reach Potential Employers',
    content:
      'The platform enables users to exchange career information and reach potential employers that seem unreachable.',
    name: 'An OnMyGrad User',
    avatar: '',
  },
  {
    heading: 'Exclusive information',
    content:
      'Industry knowledge and exclusive interview tips are not always available online, but I can always find what I need here to equip myself',
    name: 'An OnMyGrad User',
    avatar: '',
  },
  {
    heading: 'Identify graduate communities',
    content:
      "In this new generation of students it's not about the ability of the graduates but where they belong. Working with OnMyGrad has shown how we should position ourselves and adjust how we view the future workforce.",
    name: 'Mr Rocco Li',
    title: 'Head of Campus Recruitment, KPMG China & Hong Kong',
    avatar: '',
  },
  {
    heading: 'Learn from leaders & peers',
    content:
      'Career opportunities are not just about job openings, but how we prepare ourselves to get the most desirable job offers and advance our career. In OnMyGrad, I can learn from both the industry insiders and peers.',
    name: 'An OnMyGrad User',
    avatar: '',
  },
  {
    heading: 'Build employer brand',
    content:
      'An Innovative platform to reinforce employer brand and campus recruitment',
    name: 'Mr Leo Liu',
    title: 'Employer Branding & Campus Recruitment HK Lead, Accenture',
    avatar: '',
  },
]

const PARTNER_LOGOS = [
  'partner_accenture_white.png',
  'partner_alphasight_white.png',
  // 'partner_citi_white.png',
  'partner_deutsche_white.png',
  'partner_hktv_white.png',
  'partner_jardines_white.png',
  'partner_jll_white.png',
  'partner_kpmg_white.png',
  'partner_lalamove_white.png',
  'partner_livi_white.png',
  'partner_loreal_white.png',
  'partner_microsoft_white.png',
  'partner_mtr_white.png',
  'partner_png_white.png',
  'partner_pwc_white.png',
  'partner_swire_white.png',
  'partner_tal_white.png',
  'partner_unilever_white.png',
]

function ChunkPartnerLogoArray(logoList) {
  if (!logoList) {
    return []
  }
  const chunkSize = Math.ceil(logoList.length / 5)
  const result = chunk(logoList, chunkSize) || []

  return chunk(logoList, chunkSize)
}

function RootIndex({ data, location }) {
  const { t } = useTranslation()

  const [colorPosition, setColorPosition] = useState([])
  const [nextIdx, setNextIdx] = useState(0)
  const [activeFeature, setActiveFeature] = useState(0)
  const [showLogoList, setShowLogoList] = useState([0, 0, 0, 0, 0])
  const swiperRef = useRef(null)

  const partnerLogoList = ChunkPartnerLogoArray(PARTNER_LOGOS)
  const igPost = get(data, 'allContentfulInstagramPost.nodes')
  const FAQ = get(data, 'allContentfulFaq.nodes')

  const scrollPosition = useScrollPosition()
  const windowSize = useWindowSize()

  const SECTION_DICT_LIST = [
    {
      name: 'top',
      background: 'bg_base_black',
      logo: 'full',
    },
    {
      name: 'issue',
      background: 'bg_base_black',
      logo: 'yellow',
    },
    {
      name: 'feature',
      background: 'bg_base_grey',
      logo: 'yellow',
    },
    {
      name: 'join',
      background: 'bg_base_black',
      logo: 'yellow',
    },
    {
      name: 'about',
      background: 'bg_base_yellow',
      logo: 'black',
    },
    {
      name: 'testimonial',
      background: 'bg_base_grey',
      logo: 'yellow',
    },
    {
      name: 'social',
      background: 'bg_base_yellow',
      logo: 'black',
    },
  ]

  function getElOffset(el) {
    let top = 0
    let left = 0

    // grab the offset of the element relative to it's parent,
    // then repeat with the parent relative to it's parent,
    // ... until we reach an element without parents.
    do {
      if (!isNaN(el.offsetTop)) {
        top += el.offsetTop
      }
      if (!isNaN(el.offsetLeft)) {
        left += el.offsetLeft
      }
      el = el.offsetParent
    } while (el)

    return { top, left }
  }

  const { isMobile } = windowSize

  // get the element height of each section
  // update upon change in window size
  useEffect(() => {
    // setup for side nav
    if (document) {
      document.querySelectorAll('.content-container').forEach((el, idx) => {
        const { top } = getElOffset(el)
        setColorPosition((prev) => [...prev, { startY: top }])
      })
    }
    // setup reveal animation
    if (window) {
      const scrollAnimation = sal({
        rootMargin: '0% 50%',
        threshold: 0.2,
        animateClassName: 'sal-animate',
        disabledClassName: 'sal-disabled',
        enterEventName: 'sal:in',
        exitEventName: 'sal:out',
        selector: '[data-sal]',
        once: true,
        disabled: false,
      })
    }
  }, [])

  useEffect(() => {
    // check current scroll position, change class
    const numOfSection = colorPosition.length

    // const windowHeight =
    //   window && window.innerHeight ? window.innerHeight * 0.4 : 400

    const windowHeight = 400
    if (numOfSection > 0 && scrollPosition > 0) {
      let currentIndex = colorPosition.findIndex(
        ({ startY }) => startY > scrollPosition + windowHeight
      )
      if (currentIndex === -1) {
        // NOTE: if we scrolled past the last one; keep the color of the last one
        setNextIdx(numOfSection - 1)
      } else if (currentIndex === 0) {
        setNextIdx(0)
      } else {
        setNextIdx(currentIndex - 1)
      }
    }
  }, [scrollPosition, colorPosition, nextIdx])

  // logo change
  useEffect(() => {
    function getRandomInt(max) {
      max = Math.floor(max)
      return Math.floor(Math.random() * max) //The maximum is exclusive and the minimum is inclusive
    }

    function getRanIdxList() {
      let randList = []
      randList[0] = getRandomInt(partnerLogoList[0].length)
      randList[1] = getRandomInt(partnerLogoList[1].length)
      randList[2] = getRandomInt(partnerLogoList[2].length)
      randList[3] = getRandomInt(partnerLogoList[3].length)
      randList[4] = getRandomInt(partnerLogoList[4].length)
      return randList
    }

    const interval = setInterval(() => {
      setShowLogoList(getRanIdxList())
    }, 5000)

    return () => clearInterval(interval)
  }, [])

  const FeatureDict = [
    'Profile',
    'Virtual',
    'Talents',
    'Pipeline',
    'Evaluation',
  ]

  const FeatureIcon = (props) => {
    const { iconidx } = props

    const Components = {
      Profile,
      Evaluation,
      Pipeline,
      Virtual,
      Talents,
    }

    const idx = FeatureDict[iconidx]

    if (idx) {
      let Component = Components[idx]
      return <Component {...props} />
    } else {
      return
    }
  }

  const ProblemIcon = (props) => {
    const { iconidx } = props

    const Components = {
      AlarmIcon: AlarmIcon,
      EmployerBrandIcon: EmployerBrandIcon,
      AssessmentIcon: AssessmentIcon,
      OmniChannelIcon: OmniChannelIcon,
      ManyPositionIcon: ManyPositionIcon,
      DiversityIcon: DiversityIcon,
    }

    if (iconidx && typeof iconidx === 'string') {
      let Component = Components[iconidx]
      return <Component {...props} />
    } else {
      return null
    }
  }

  return (
    <Layout
      className="scroll-smooth"
      location={location}
      logoType={SECTION_DICT_LIST[nextIdx].logo}
      scrollPosition={scrollPosition}
    >
      <Seo image={ogImage} />
      {/* Side Dot Nav */}
      <nav
        className={cx(
          styles.navigation,
          styles.navigation__active,
          'ml-6 md:ml-0'
        )}
      >
        {map(SECTION_DICT_LIST, (section, idx) => (
          <a
            key={`nav-${idx}`}
            role="button"
            label={section.name}
            className={cx(styles.navigation__link, {
              [styles.navigation__link__active]: nextIdx === idx,
            })}
            href={`#${section.name}`}
            data-name={section.name}
          >
            <span style={{ display: 'none' }}>{section.name}</span>
          </a>
        ))}
      </nav>
      {/* END Side dot nav */}
      <section
        id="top"
        className="content-container flex overflow-hidden relative pt-40 md:pt-56"
      >
        <div className="absolute h-full w-full top-0 left-0">
          <div className={styles.neohero}></div>
          <div className={styles.neohero_g}></div>
          <div className={styles.herobackdrop}></div>
        </div>
        <div className="z-10 container px-6 py-16 mx-auto text-center">
          <div className="max-w-4xl mx-auto">
            <h1 className="text-4xl font-bold text-gray-800  md:text-5xl">
              Supercharging Campus Recruitment with{' '}
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-orange-300">
                virtual job experience
              </span>
            </h1>
            <p className="mx-auto mt-6 text-gray-500 max-w-2xl text-center">
              Employers across different industries to connect with top talents
              in universities with virtual job work experience programs in
              Southeast Asia
            </p>
            <a
              href="#join"
              className="inline-block px-6 py-4 mt-12 font-bold leading-5 text-center text-bold text-black capitalize bg-brand rounded-lg hover:bg-yellow-400 md:mx-0 md:w-auto focus:outline-none"
            >
              Join Whitelist
            </a>
          </div>
          <div className="flex justify-center mt-12">
            {/* <img
              class="object-contain w-full h-auto max-w-2xl"
              src={heroMock}
              alt="OnMyGrad Platform concept"
            /> */}

            <video
              loop
              src={heroVideo}
              autoPlay
              muted
              className="rounded-2xl"
              controls=""
            />
          </div>
        </div>
      </section>

      <section
        id="issue"
        className="content-container min-h-screen mt-16 md:mt-32 container mx-auto"
      >
        <div
          className="px-8 md:px-12 lg:px-20  md:mb-20"
          data-sal={!isMobile && 'slide-up'}
          data-sal-duration="1000"
          data-sal-delay="100"
          data-sal-easing="ease"
        >
          <h2 className="text-3xl font-bold text-gray-800 md:text-4xl md:max-w-2xl">
            Why campus recruitment becomes more complicated then ever?
          </h2>
          <p className="text-lg text-gray-500 mt-2">
            with limited resources, employers are competing to build teams with
            top candidates
          </p>

          <div className="block mt-12 md:grid md:grid-cols-3 md:gap-6">
            {map(TEMP_PROBLEMS, ({ title, des, icon }, key) => {
              return (
                <div class="pb-12 md:pb-8" key={`prob-${key}`}>
                  <div class="w-full text-center">
                    <span class="mx-auto inline-block p-3 text-yellow-700 bg-yellow-100 rounded-xl">
                      <ProblemIcon class="h- w-" iconidx={icon} />{' '}
                    </span>
                  </div>
                  <h6 class="text-gray-800 text-xl text-center font-bold mb-3 mt-2">
                    {title}
                  </h6>
                  <div class="border-t-2 border-t-brand pt-2 text-gray-500 w-4/5 md:w-full m-auto">
                    {des}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>

      <div className="flex w-full h-80 bg-gradient-to-r from-brand to-pink-200">
        <div className="container m-auto px-8 md:pb-8 md:px-12 lg:px-20  h-full items-center md:items-end md:justify-between sm:flex">
          <h2 className="text-gray-800  font-bold text-3xl md:text-4xl md:w-2/3 ">
            {' '}
            Campus recruitment teams can save up to{' '}
            <span className="underline decoration-cyan-500 hover:decoration-cyan-600">
              50% effort
            </span>{' '}
            {` `}with OnMyGrad
          </h2>
        </div>
      </div>

      <section
        id="feature"
        className="content-container mt-16 md:mt-32 container mx-auto min-h-[50vh]"
      >
        <div
          className="px-8 md:px-12 lg:px-20 "
          data-sal={!isMobile && 'slide-up'}
          data-sal-duration="1000"
          data-sal-delay="100"
          data-sal-easing="ease"
        >
          <div className={cx(styles.feature_des_wrapper)}>
            <div
              className={cx(styles.flx_row, styles.feature_select_container)}
            >
              {map(TEMP_FEATURE, (item, idx) => {
                return (
                  <div
                    className={cx(styles.feature_item_container, {
                      [styles.feature_select__active]: activeFeature >= idx,

                      [styles.feature_select__active_major]:
                        activeFeature === idx,
                    })}
                    key={`fea-${idx}`}
                  >
                    <div className={styles.feature_item_dot}>
                      <span></span>
                      <span></span>
                    </div>
                    <div
                      className={styles.feature_item_content}
                      onClick={() => setActiveFeature(idx)}
                    >
                      <div className={styles.feature_select_wrapper_outring}>
                        <div
                          className={cx(
                            styles.feature_select_wrapper,
                            styles[`feature_select_wrapper_bg_${idx}`],
                            {
                              [styles.feature_select__active]:
                                activeFeature === idx,
                            }
                          )}
                          key={`feature-${idx}`}
                        >
                          <FeatureIcon iconidx={idx} />
                        </div>
                      </div>

                      <h4 className="mt-2 md:mt-0">{`${FeatureDict[idx]}`}</h4>
                    </div>
                  </div>
                )
              })}
            </div>
            <div className={styles.feature_wrapper}>
              <div className="w-full md:w-2/3">
                <div>
                  <div className="text-gray-400 uppercase font-bold mt-2 md:mt-0">
                    feature
                  </div>
                  <h3 className="text-2xl md:text-3xl mb-4 md:mb-8">
                    {TEMP_FEATURE[activeFeature] &&
                      TEMP_FEATURE[activeFeature]?.title}
                  </h3>
                </div>

                <ul>
                  {TEMP_FEATURE[activeFeature] &&
                    TEMP_FEATURE[activeFeature]?.content &&
                    TEMP_FEATURE[activeFeature].content.length > 0 &&
                    TEMP_FEATURE[activeFeature].content.map((item, idx) => {
                      return (
                        <li key={`feature-${activeFeature}-${idx}`}>{item}</li>
                      )
                    })}
                </ul>
              </div>

              <div className={styles.feature_image_wrapper}>
                <img
                  className={cx(styles.feature_image, {
                    [styles.feature_image_show]:
                      FeatureDict[activeFeature] === 'Evaluation',
                  })}
                  src={'/Evaluation.png'}
                  alt="Evaluation"
                />
                <img
                  className={cx(styles.feature_image, {
                    [styles.feature_image_show]:
                      FeatureDict[activeFeature] === 'Pipeline',
                  })}
                  src={'/Pipeline.png'}
                  alt="Pipeline"
                />
                <img
                  className={cx(styles.feature_image, {
                    [styles.feature_image_show]:
                      FeatureDict[activeFeature] === 'Profile',
                  })}
                  src={'/Profile.png'}
                  alt="Profile"
                />
                <img
                  className={cx(styles.feature_image, {
                    [styles.feature_image_show]:
                      FeatureDict[activeFeature] === 'Talents',
                  })}
                  src={'/Talents.png'}
                  alt="Talents"
                />
                <img
                  className={cx(styles.feature_image, {
                    [styles.feature_image_show]:
                      FeatureDict[activeFeature] === 'Virtual',
                  })}
                  src={'/Virtual.png'}
                  alt="Virtual"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="join"
        class="content-container container px-8 md:px-12 lg:px-20  m-auto my-12 md:my-36"
      >
        <SignUpForm />
      </section>

      <section id="about" className="content-container">
        <div className="relative">
          <div
            data-sal={!isMobile && 'slide-up'}
            data-sal-duration="1000"
            data-sal-delay="100"
            data-sal-easing="ease"
          >
            <div className="z-10 px-8 w-full md:w-2/3 marker:font-gray-800 text-xl mx-auto">
              <h2 className="text-3xl font-bold text-gray-800 md:text-4xl mb-4 md:mb-6">
                About OnMyGrad Team
              </h2>
              <p className="first-letter:text-2xl leading-loose mb-8">
                Started in 2021, OnMyGrad is a creative and ambitious team to
                solve the problems in campus recruitment. OnMyGrad's platform
                enables leading employers across different industries to connect
                with top talents in universities with virtual job work
                experience programs.
              </p>
              <p className="first-letter:text-2xl leading-loose">
                Furthermore, the team aims at extending the space to a regional
                level allowing students from different geographical locations to
                work together.
              </p>
            </div>
          </div>
          {/* <div className={styles.about_bg_container}></div>
          <div className={styles.about_onmygrad_title_wrapper}>
            <div className={styles.about_onmygrad_title_track}>
              <h2 className={styles.about_onmygrad_text}>
                OnMyGrad OnMyGrad OnMyGrad OnMyGrad OnMyGrad OnMyGrad
              </h2>
            </div>
          </div> */}
        </div>
      </section>
      <section
        id="testimonial"
        className="content-container  mt-16 md:mt-32 container mx-auto"
      >
        <div
          className={cx(styles.content_wrapper)}
          data-sal={!isMobile && 'slide-up'}
          data-sal-duration="1000"
          data-sal-delay="100"
          data-sal-easing="ease"
        >
          <div class="px-8 pt-16 pb-8 mx-auto md:px-12 lg:px-20  sm:pt-24 sm:pb-8">
            <div class="grid grid-cols-1 lg:grid-cols-3 lg:gap-x-16 gap-y-8 lg:items-center">
              <div class="max-w-xl text-center sm:text-left">
                <h2 class="text-gray-800 md:text-4xl font-bold tracking-tight text-3xl">
                  Well-tested in the market
                </h2>

                <p class="mt-4 text-gray-500">
                  Since the{' '}
                  <a
                    href="https://www.onmygrad.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    MVP version
                  </a>{' '}
                  launched in Feb 2021, our team has been working closely with
                  leading employers to create a more efficient campus
                  recruitment space. We are now building a brand new platform to
                  further stretch the reality virtual job experience programmes.
                </p>
                {/* wide screen nav button */}
                <div className="justify-center mt-8 flex gap-4 lg:justify-start">
                  <button
                    aria-label="Previous slide"
                    className="p-3 text-white bg-brand border border-brand rounded-full hover:bg-yellow-400 hover:text-gray-500 prev-button"
                    onClick={() => swiperRef.current.swiper.slidePrev()}
                  >
                    <svg
                      class="w-5 h-5 transform -rotate-180"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 5l7 7-7 7"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />
                    </svg>
                  </button>

                  <button
                    aria-label="Next slide"
                    className="p-3 text-white bg-brand border border-brand rounded-full hover:bg-yellow-400 hover:text-gray-500 next-button"
                    onClick={() => swiperRef.current.swiper.slideNext()}
                  >
                    <svg
                      class="w-5 h-5"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 5l7 7-7 7"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />
                    </svg>
                  </button>
                </div>
              </div>

              <div class="-mx-6 lg:col-span-2 lg:mx-0">
                <Swiper ref={swiperRef}>
                  {map(
                    TEMP_TESTIMONIAL,
                    ({ heading, content, name, title }, idx) => (
                      <SwiperSlide key={`text-swiper-${idx}`}>
                        <blockquote class="md:mb-4 md:mr-12 rounded-xl shadow-lg flex flex-col justify-between h-full p-4 md:p-12 bg-white">
                          <div class="mt-4">
                            <p class="text-2xl font-bold text-brand sm:text-3xl">
                              {heading}
                            </p>

                            <p class="mt-4 leading-relaxed text-gray-500">
                              {content}
                            </p>
                          </div>

                          <div class="mt-8 text-sm text-gray-500">
                            &mdash; {`${name} ${title ? `(${title})` : ''}`}
                          </div>
                        </blockquote>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
        <div className="px-8 mx-auto md:px-12 lg:px-20 ">
          <h4 className="text-base text-gray-500 font-bold text-center">
            Trusted by 30+ top employers in Southeast Asia
            {/* <span className={styles.partner_call_highlight}>
                Future of Work
              </span>{' '} */}
          </h4>
          <div className="grid grid-cols-2 md:grid-cols-5 gap-6 ">
            {map(partnerLogoList, (item, idx) => {
              return (
                <div
                  className={cx(
                    `h-20 w-auto relative brightness-75`,
                    `${idx >= 2 && 'hidden md:block'}`
                  )}
                  key={`test-${idx}`}
                >
                  {' '}
                  {map(item, (j, x) => {
                    return (
                      <div
                        style={{
                          backgroundImage: `url(/logos/${j})`,
                        }}
                        className={cx(
                          'absolute h-20 w-40 bg-no-repeat bg-contain bg-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 grayscale',
                          'opacity-0 transition-opacity	duration-500 ease-in-out',
                          `${
                            showLogoList[idx] === x
                              ? `opacity-100`
                              : `opacity-0`
                          }`
                        )}
                        key={`logo-${idx}-${x}`}
                      />
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>
      </section>
      <section
        id="social"
        className="content-container flex container m-auto px-8 md:px-12 lg:px-20  md:mb-20 min-h-screen"
      >
        <div
          className={cx(
            'flex flex-col md:flex-row m-auto',
            styles.flx_mobile_column
          )}
          data-sal={!isMobile && 'slide-up'}
          data-sal-duration="1000"
          data-sal-delay="100"
          data-sal-easing="ease"
        >
          <div className="w-1/2 m-auto md:w-3/5">
            {igPost && <IGPost posts={igPost} />}
          </div>
          <div className={cx('w-full md:w-2/5 md:pl-12')}>
            <h4 className={'mt-4 md:mt-0 font-bold text-4xl mb-4 md:mb-6'}>
              {t('Follow us')}
            </h4>
            <div className="flex flex-row flex-wrap">
              {map(
                ['#internship', '#interviewtips', '#careerpath'],
                (item, idx) => {
                  return (
                    <small
                      className="mr-2 px-1 py-2 bg-gray-200 rounded-md shadow-sm"
                      key={`tag-${idx}`}
                    >
                      {item}
                    </small>
                  )
                }
              )}
            </div>
            <p class="mt-8 text-lg">
              Want to know the latest trend in campus recruitment? Want to get
              the involved in OnMyGrad's student communities?
            </p>
            <p class="mt-8 text-lg">
              Follow us on instagram{' '}
              <a
                class="text-pink-500 hover:text-brand underline"
                href="https://www.instagram.com/onmygrad/"
                target="_blank"
                rel="noreferrer"
              >
                @onmygradqna
              </a>{' '}
              and{' '}
              <a
                class="text-pink-500 hover:text-brand underline"
                href="https://www.instagram.com/ongrad/"
                target="_blank"
                rel="noreferrer"
              >
                @onmygrad
              </a>{' '}
              now!
            </p>
          </div>
        </div>
      </section>

      {FAQ && (
        <div class=" px-8 pt-16 pb-8 mx-auto md:px-12 lg:px-20 ">
          <div class="mx-auto max-w-6xl flex flex-col md:flex-row">
            <h2 class="mb-4 md:mb-0 mr-8 w-full md:w-1/3 text-3xl font-extrabold leading-9">
              Frequently-asked questions
            </h2>

            <dl class="w-full md:w-2/3">
              {map(FAQ, (item, idx) => (
                <div key={`faq-${idx}`}>
                  <dt class="mb-4">
                    <h3 class="text-xl font-semibold text-gray-900">
                      {item.question}
                    </h3>
                  </dt>
                  <dd class="mb-16">
                    <p className="text-gray-700">
                      {item.answer_content?.answer_content}
                    </p>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      )}
    </Layout>
  )
}

export default RootIndex

// query for
export const pageQuery = graphql`
  query HomeQuery($language: String!, $locale: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allFile {
      nodes {
        relativePath
      }
    }
    allContentfulInstagramPost(limit: 6) {
      nodes {
        id
        title
        url
        image {
          gatsbyImageData
        }
      }
    }
    allContentfulFaq(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: order, order: ASC }
    ) {
      nodes {
        question
        answer_content {
          answer_content
        }
      }
    }
  }
`
