import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import React, { useEffect, useState } from 'react'
import { map, get } from 'lodash'
import * as styles from './igPost.module.css'

const IGPost = ({ posts }) => {
  return (
    <div className={styles.ig_post_container}>
      {map(posts, (post, key) => {
        return (
          <div className={styles.img_wrapper} key={`social-${key}`}>
            <a href={post?.url} target="_blank">
              <GatsbyImage
                style={{ overflow: 'visible' }}
                imgStyle={{
                  borderRadius: '20px',
                  boxShadow: '0px 6px 15px rgba(0,0,0,0.25)',
                }}
                image={post.image.gatsbyImageData}
                alt={post?.title}
                key={key}
              />
            </a>
          </div>
        )
      })}
    </div>
  )
}

export default IGPost
