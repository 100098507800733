// extracted by mini-css-extract-plugin
export var about_omg_content = "index-module--about_omg_content--778ed";
export var about_omg_paragraph_1 = "index-module--about_omg_paragraph_1--df164";
export var about_onmygrad_title_wrapper = "index-module--about_onmygrad_title_wrapper--85305";
export var animate = "index-module--animate--a87d2";
export var cta_inline_wrapper = "index-module--cta_inline_wrapper--c5646";
export var feature_des = "index-module--feature_des--63db7";
export var feature_des_container = "index-module--feature_des_container--757e3";
export var feature_des_wrapper = "index-module--feature_des_wrapper--ce5ec";
export var feature_image = "index-module--feature_image--7dd8d";
export var feature_image_show = "index-module--feature_image_show--3f5e1";
export var feature_image_wrapper = "index-module--feature_image_wrapper--0892d";
export var feature_intro = "index-module--feature_intro--9a5b6";
export var feature_item_container = "index-module--feature_item_container--06393";
export var feature_item_content = "index-module--feature_item_content--79b9e";
export var feature_item_dot = "index-module--feature_item_dot--7a27f";
export var feature_select__active = "index-module--feature_select__active--97d3c";
export var feature_select__active_major = "index-module--feature_select__active_major--afaa9";
export var feature_select_container = "index-module--feature_select_container--444fe";
export var feature_select_wrapper = "index-module--feature_select_wrapper--1ed72";
export var feature_select_wrapper_bg_0 = "index-module--feature_select_wrapper_bg_0--e378d";
export var feature_select_wrapper_bg_1 = "index-module--feature_select_wrapper_bg_1--3ea76";
export var feature_select_wrapper_bg_2 = "index-module--feature_select_wrapper_bg_2--f0d84";
export var feature_select_wrapper_bg_3 = "index-module--feature_select_wrapper_bg_3--c49e5";
export var feature_select_wrapper_bg_4 = "index-module--feature_select_wrapper_bg_4--99027";
export var feature_select_wrapper_outring = "index-module--feature_select_wrapper_outring--1b3cb";
export var feature_title = "index-module--feature_title--e97a3";
export var feature_title_select = "index-module--feature_title_select--ed30b";
export var feature_wrapper = "index-module--feature_wrapper--99b1b";
export var flx_mobile_column = "index-module--flx_mobile_column--bab2f";
export var flx_row = "index-module--flx_row--1dc95";
export var flx_wrap = "index-module--flx_wrap--d17f8";
export var herobackdrop = "index-module--herobackdrop--ae260";
export var ig_content_wrapper = "index-module--ig_content_wrapper--ab3e3";
export var navigation = "index-module--navigation--2751f";
export var navigation__active = "index-module--navigation__active--57bb8";
export var navigation__link = "index-module--navigation__link--38bcd";
export var navigation__link__active = "index-module--navigation__link__active--95cf8";
export var neohero = "index-module--neohero--7dec0";
export var neohero_g = "index-module--neohero_g--6f9c2";
export var one_liner = "index-module--one_liner--37531";
export var partner_logo_500 = "index-module--partner_logo_500--f595e";
export var partner_logo_aw = "index-module--partner_logo_aw--18063";
export var partner_logo_container = "index-module--partner_logo_container--77336";
export var partner_logo_item = "index-module--partner_logo_item--5907b";
export var partner_support_call = "index-module--partner_support_call--e238b";
export var partner_support_container = "index-module--partner_support_container--4f14d";
export var problem_container = "index-module--problem_container--1f6e8";
export var rolling_cta_both = "index-module--rolling_cta_both--62609";
export var section__title__sticky = "index-module--section__title__sticky--aed84";
export var section__title_container = "index-module--section__title_container--9d895";
export var testimonial_button_side = "index-module--testimonial_button_side--1a626";
export var testimonial_button_under = "index-module--testimonial_button_under--18c21";
export var testimonial_content = "index-module--testimonial_content--322ec";
export var testimonial_content_content = "index-module--testimonial_content_content--d84d5";
export var testimonial_item = "index-module--testimonial_item--363d3";