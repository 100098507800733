import React, { useRef } from 'react'

const SignUpForm = () => {
  const CONTACT_URL = process.env.GATSBY_CONTACT_FORM_URL
  const inputEl = useRef(null)
  const onFormSubmit = (e) => {
    console.log('here')
    e.preventDefault()
    // check if have contact URL

    // check if the input is ok
    if (e.target[0].value !== '') {
      const data = JSON.stringify({ email: e.target[0].value })
      const bodyContent = JSON.stringify({ body: data })
      fetch(CONTACT_URL, {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
        body: bodyContent,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('Success:', data)
          inputEl.current.value = ''
          // setSubmitted(true)
        })
        .catch((error) => {
          console.error('Error:', error)
          // setSubmitError(true)
        })
    }
  }
  return (
    <section>
      <div class="container px-4 py-16 mx-auto lg:flex lg:items-center lg:justify-between">
        <div>
          <h2 class="text-2xl font-semibold tracking-tight">
            Want to be among first-movers?
          </h2>
          <h2 className="font-bold text-transparent text-4xl md:text-6xl bg-clip-text bg-gradient-to-r from-brand to-pink-400">
            Join our whitelist
          </h2>

          <div class="mt-6 md:mt-10">
            <form onSubmit={onFormSubmit}>
              <input
                id="email"
                type="email"
                ref={inputEl}
                class="px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md sm:mx-2  focus:border-pink-400 focus:ring-pink-300 focus:outline-none focus:ring focus:ring-opacity-40"
                placeholder="Email Address"
              />

              <input
                type="submit"
                label="Join Whitelist"
                class="px-4 py-2 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-200 transform bg-brand rounded-md focus:ring focus:ring-pink-300 focus:ring-opacity-80 fo sm:mx-2 hover:bg-yellow-500 focus:outline-none focus:bg-yellow-500"
              />
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SignUpForm
